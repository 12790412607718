<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
    >
      <el-form-item label="配餐公司" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          :disabled="disabled"
          placeholder="请选择配餐公司"
          @change="getOrgPeriodList"
        >
          <el-option
            v-for="item in orgList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="isGroup">
        <el-checkbox v-model="dataForm.isGroup" :disabled="disabled"
          >是否团餐</el-checkbox
        >
      </el-form-item>
      <el-form-item prop="diningNum" v-if="dataForm.isGroup" label="用餐人数">
        <el-input-number
          v-model="dataForm.diningNum"
          :disabled="disabled"
          placeholder="团餐人数"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="供应时段" prop="periodIds">
        <el-select
          v-model="periodIds"
          :disabled="disabled"
          multiple
          placeholder="供应时段"
        >
          <el-option
            v-for="item in periodList"
            :key="item.periodId"
            :label="item.periodWithTimeName"
            :value="item.periodId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放地址" prop="address">
        <el-input
          v-model="dataForm.address"
          :disabled="disabled"
          placeholder="投放地址"
          @change="tranAddress"
        ></el-input>
        <div class="mapBlock">
          <el-button @click="openForm" type="info" v-if="!chooseVisible"
            >打开地图</el-button
          >
          <el-button @click="closeForm" type="warning" v-else
            >关闭地图</el-button
          >
          <map-choose
            ref="mapchoose"
            v-if="chooseVisible"
            @newAddress="getNewAddress"
            class="mapWay"
          />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
      <el-button v-if="isApply" type="primary" @click="approvedApply()"
        >审核通过</el-button
      >
      <el-button v-if="isApply" type="primary" @click="rejectApply()"
        >驳回</el-button
      >
    </span>
    <!-- 弹窗, 新增 / 修改 -->
    <freezer-add-or-update
      v-if="freezerAddOrUpdateVisible"
      ref="freezerAddOrUpdate"
    ></freezer-add-or-update>
  </el-dialog>
</template>

<script>
import { listMixin, normal, threeListMixin } from '@/mixins';
import { getOrgList, getOrgPeriodList } from '@/utils/options';
import FreezerAddOrUpdate from './freezer-add-or-update';
import mapChoose from '@/components/addressChoose';
import { pick, keys } from 'lodash';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      disabled: false,
      visible: false,
      isApply: false,
      chooseVisible: false,
      markersLayer: null,
      geometries: null,
      freezerAddOrUpdateVisible: false,
      orgList: [],
      periodList: [],
      periodIds: [],
      dataForm: {
        id: 0,
        orgId: '',
        diningNum: '',
        isGroup: true,
        periodIds: '',
        address: '',
        longitude: null,
        latitude: null,
      },
      dataRule: {
        orgId: [
          {
            required: true,
            message: '请选择配餐公司',
            trigger: 'blur',
          },
        ],
        periodIds: [
          {
            required: true,
            message: '请选择供应时段',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '请输入投放地址',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  components: {
    mapChoose,
    FreezerAddOrUpdate,
  },
  created() {
    this.getOrgList();
  },
  methods: {
    init(id, disabled, isApply) {
      this.periodIds = [];
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.isApply = isApply;
      this.visible = true;
      this.openForm();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/cc/freezerApplication/info/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.freezerApplication, keys(this.dataForm));
              this.periodIds = this.dataForm.periodIds
                .split(',')
                .map((item) => parseInt(item));
              this.getOrgPeriodList();
            }
          });
        }
      });
    },
    openForm() {
      this.chooseVisible = true;
      this.$nextTick(() => {
        if (this.dataForm.address) {
          this.$refs.mapchoose.getAddress(this.dataForm.address);
        } else {
          this.$refs.mapchoose.getAddress('佛山市');
        }
      });
    },
    closeForm() {
      this.chooseVisible = false;
    },
    getNewAddress(address) {
      this.dataForm.address = address;
      this.tranAddress(address);
    },
    async tranAddress(data) {
      if (this.chooseVisible) {
        const address = await this.$refs.mapchoose.getAddress(data);
        if (address !== null) {
          this.dataForm.latitude = address.latitude;
          this.dataForm.longitude = address.longitude;
        }
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.periodIds = this.periodIds.join(',');
      if (!this.dataForm.isGroup) {
        this.dataForm.diningNum = 0;
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/freezerApplication/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    getOrgPeriodList() {
      if (this.dataForm.orgId) {
        getOrgPeriodList(this.dataForm.orgId).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    approvedApply() {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.$http({
          url: `/cc/freezerApplication/update`,
          method: 'post',
          data: {
            id: this.dataForm.id,
            state: 1,
            remarks: value,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$confirm('操作成功，是否前往智能取餐柜新增页面？', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning',
            })
              .then(() => {
                this.$router.replace({
                  path: '/adjust-freezer',
                });
              })
              .catch(() => {
                this.$emit('refreshDataList');
              });
            this.visible = false;
          }
        });
      });
    },
    rejectApply() {
      this.$prompt('请输入驳回理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '输入不能为空',
      }).then(({ value }) => {
        this.$http({
          url: `/cc/freezerApplication/update`,
          method: 'post',
          data: {
            id: this.dataForm.id,
            state: 2,
            remarks: value,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
            });
            this.visible = false;
            this.$emit('refreshDataList');
          }
        });
      });
    },
  },
};
</script>
